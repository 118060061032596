import { createContext, ReactNode, useContext, useEffect, useState } from "react";

interface EnvironmentContextType {
    environment: "staging" | "production";
    setEnvironment: (environment: "staging" | "production") => void;
}

const EnvironmentContext = createContext<EnvironmentContextType>({
    environment: "production",
    setEnvironment: () => { }
});

export function EnvironmentProvider({ children }: { children: ReactNode }) {
    const [environment, setEnvironment] = useState<"staging" | "production">("production");

    useEffect(() => {
        const savedEnv = localStorage.getItem("environment") as "staging" | "production" | null;
        if (savedEnv && (savedEnv === "staging" || savedEnv === "production")) {
            setEnvironment(savedEnv);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("environment", environment);
    }, [environment]);

    return (
        <EnvironmentContext.Provider value={{ environment, setEnvironment }}>
            {children}
        </EnvironmentContext.Provider>
    );
}

export function useEnvironment() {
    return useContext(EnvironmentContext);
}