import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserProvider } from './components/Providers/UserProvider';
import { EnvironmentProvider } from './components/Providers/EnvironmentProvider';

function App() {
  const content = useRoutes(router);
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <EnvironmentProvider>
          <UserProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              {content}
            </LocalizationProvider>
          </UserProvider>
        </EnvironmentProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
export default App;
