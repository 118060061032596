import {
  Box,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  () => `
        width: 52px;
        height: 38px;
`
);

const LogoSign = styled(Box)(
  ({ theme }) => `
        background: ${theme.general.reactFrameworkColor};
        width: 18px;
        height: 18px;
        border-radius: ${theme.general.borderRadiusSm};
        position: relative;
        transform: rotate(45deg);
        top: 3px;
        left: 17px;

        &:after, 
        &:before {
            content: "";
            display: block;
            width: 18px;
            height: 18px;
            position: absolute;
            top: -1px;
            right: -20px;
            transform: rotate(0deg);
            border-radius: ${theme.general.borderRadiusSm};
        }

        &:before {
            background: ${theme.palette.primary.main};
            right: auto;
            left: 0;
            top: 20px;
        }

        &:after {
            background: ${theme.palette.secondary.main};
        }
`
);

const LogoSignInner = styled(Box)(
  ({ theme }) => `
        width: 16px;
        height: 16px;
        position: absolute;
        top: 12px;
        left: 12px;
        z-index: 5;
        border-radius: ${theme.general.borderRadiusSm};
        background: ${theme.header.background};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));


const SvgLogo = ({ dark = true }) => {
  const fillColor = dark ? '#101111' : '#ffffff';

  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      data-bbox="0.824 0.417 98.352 99.166"
      viewBox="0.824 0.417 98.352 99.166"
      xmlns="http://www.w3.org/2000/svg"
      data-type="color"
      role="presentation"
      aria-hidden="true"
      aria-label=""
      height={24}
      width={24}
    >
      <defs>
        <style>{`#comp-kk2o699z svg [data-color="1"] {fill: ${fillColor};}`}</style>
      </defs>
      <g>
        <path
          d="M85.514 55.05V13.397L63.386.823h-21.99l33.189 18.85v41.663zM28.148 78.683l35.238 20.493h-.138l.545.407 22.68-13.11 10.923-18.988L64.07 86.88 28.148 66.118z"
          fill={fillColor}
          data-color="1"
        />
        <path
          d="M72.128 66.394h.138l-19.672 11.2 10.792 6.278 35.79-20.893V36.614l-10.93-18.989v38.93zm-.276-45.215L36.07.417l-22.674 13.11-10.93 19.126L36.339 13.12l16.394 9.563h-.276l19.396 11.199z"
          fill={fillColor}
          data-color="1"
        />
        <path
          d="m36.614 16.259-35.79 20.9v26.227l10.93 18.989v-38.93l16.118-9.563 19.672-11.337z"
          fill={fillColor}
          data-color="1"
        />
        <path
          d="M14.486 44.95v41.654l22.128 12.572h22.128l-33.327-18.85V38.663z"
          fill={fillColor}
          data-color="1"
        />
      </g>
    </svg>
  );
};

function Logo(
  {
    dark = true
  }: {
    dark?: boolean;
  }
) {
  const theme = useTheme();

  return (
    <TooltipWrapper
      title="Tokyo Free White React Typescript Admin Dashboard"
      arrow
    >
      <LogoWrapper to="/overview">
        {/* <Badge
          sx={{
            '.MuiBadge-badge': {
              fontSize: theme.typography.pxToRem(11),
              right: -2,
              top: 8
            }
          }}
          overlap="circular"
          color="success"
          badgeContent="2.0"
        >
          <LogoSignWrapper>
            <LogoSign>
              <LogoSignInner />
            </LogoSign>
          </LogoSignWrapper>
        </Badge> */}

        <Box
          flexDirection={"row"}
          display={"flex"}
        >
          <SvgLogo dark={dark} />
          <Typography
            variant={"h6"}
            sx={{
              ml: 1,
            }}
            color={dark ? "#101111" : "#ffffff"}
          >
            Limpio
          </Typography>
        </Box>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
